var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"saveFormRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"courseId"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Khóa học "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Khóa học","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"courseId","options":_vm.courses,"label":"name","reduce":function (option) { return option.id; },"clearable":false},model:{value:(_vm.dataMain.courseId),callback:function ($$v) {_vm.$set(_vm.dataMain, "courseId", $$v)},expression:"dataMain.courseId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tên đề tài khóa luận "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Tên khóa luận","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","name":"name","placeholder":"Tên đề tài khóa luận","state":_vm.getElementState(errors)},model:{value:(_vm.dataMain.name),callback:function ($$v) {_vm.$set(_vm.dataMain, "name", $$v)},expression:"dataMain.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"description"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Mô tả "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Mô tả"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","name":"description","placeholder":"Mô tả","state":_vm.getElementState(errors)},model:{value:(_vm.dataMain.description),callback:function ($$v) {_vm.$set(_vm.dataMain, "description", $$v)},expression:"dataMain.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Số thứ tự","label-for":"orderNo"}},[_c('b-form-input',{attrs:{"id":"orderNo","type":"number"},model:{value:(_vm.dataMain.orderNo),callback:function ($$v) {_vm.$set(_vm.dataMain, "orderNo", $$v)},expression:"dataMain.orderNo"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Trạng thái","label-for":"status"}},[_c('v-select',{attrs:{"id":"status","options":_vm.optionsStatus,"reduce":function (option) { return option.value; },"clearable":false},model:{value:(_vm.dataMain.status),callback:function ($$v) {_vm.$set(_vm.dataMain, "status", $$v)},expression:"dataMain.status"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"float-left"},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"submit","variant":"primary","disabled":invalid},on:{"click":_vm.handleSaveData}},[_vm._v(" Lưu ")])],1)])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }