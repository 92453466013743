<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-form>
      <b-row>
        <b-col cols="12">
          <b-form-group label-for="courseId">
            <template v-slot:label>
              Khóa học <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Khóa học"
              rules="required"
            >
              <v-select
                id="courseId"
                v-model="dataMain.courseId"
                :options="courses"
                label="name"
                :reduce="option => option.id"
                :clearable="false"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label-for="name">
            <template v-slot:label>
              Tên đề tài khóa luận <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Tên khóa luận"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="dataMain.name"
                name="name"
                placeholder="Tên đề tài khóa luận"
                :state="getElementState(errors)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label-for="description">
            <template v-slot:label>
              Mô tả <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Mô tả"
            >
              <b-form-textarea
                id="description"
                v-model="dataMain.description"
                name="description"
                placeholder="Mô tả"
                :state="getElementState(errors)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Số thứ tự"
            label-for="orderNo"
          >
            <b-form-input
              id="orderNo"
              v-model="dataMain.orderNo"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Trạng thái"
            label-for="status"
          >
            <v-select
              id="status"
              v-model="dataMain.status"
              :options="optionsStatus"
              :reduce="option => option.value"
              :clearable="false"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <div class="float-left">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="handleSaveData"
            >
              Lưu
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BCol, BRow, BForm, BFormGroup, BFormInput, BFormSelect, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { STATUSES } from '@/const/status'
import vSelect from 'vue-select'
import { getUser } from '@/auth/utils'
import {Flag} from "@/const/flag";

export default {
  name: 'FormBuilding',
  components: {
    ValidationObserver,
    ValidationProvider,
    BButton,
    BCol,
    BRow,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    vSelect,
  },
  props: {
    dataForm: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      user: getUser(),
      formErr: {
        name: '',
        description: '',
      },
      optionsStatus: [],
      required,
    }
  },
  computed: {
    // calculate data from init data
    ...mapGetters({
      courses: 'graduationDissertation/courses',
    }),
  },
  async created() {
    // init data FROM API
    const dataObj = {
      currentPage: 1,
      itemsPerPage: 1000,
      organizationId: getUser().orgId,
    }
    this.dataMain = { ...{ employeeId: this.user.employeeId }, ...this.dataForm }
    await this.getCourses(dataObj)
    this.optionsStatus = STATUSES
    if (!this.dataMain.id) {
      this.dataMain.status = Flag.ACTIVE
    }
  },
  methods: {
    ...mapActions({
      getCourses: 'graduationDissertation/getCourses',
    }),
    // handle even
    handleSaveData(e) {
      const data = this.dataMain
      this.$emit('saveDataForm', data)
      e.preventDefault()
    },

    resetForm() {
      this.$nextTick(() => {
        this.$refs.observer.reset()
      })
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
